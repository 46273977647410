import React, { useRef, useState } from 'react';
import axios from 'axios';
import NotaA4 from './NotaA4';
import { obliczDatyNoty } from '../../functions/dataFormat';
import { fetchNoty } from '../../Redux/Actions';
import { delay } from '../../functions/utilities';

const NotyMasowa = ( props ) => {

  const { tablicaObiektow, setWysylkaStan, wspolnota, tablicaCzynsze, lokale, setLoaderOn, activeDock, setActiveDock } = props;
  
  const divRefs = useRef([]);
  const [showAll, setShowAll] = useState(false);

  const addToHistory = async (obj) => {
    try {
      // Wysłanie PDF na backend
      const response = await axios.post('/api/noty/dodaj', obj);

      if (response.data.success) {
        fetchNoty();
      } else {
        alert('Wystąpił problem z wysyłaniem danych.');
      }
    } catch (error) {
      console.error('Błąd podczas wysyłania danych.', error);
    }
  }

  const [docksList, setDockList] = useState([]);

  const handleGeneratePDF = async () => {
    for (let i = 0; i < divRefs.current.length; i++) {
      
      const { ref, refNr } = divRefs.current[i];
      const htmlContent = ref.outerHTML;

      const obiekt = tablicaObiektow?.find( t => t?.nota == refNr);
      const msc = obliczDatyNoty()?.miesiacNaliczenia;
      const fileName = `Nota obciążeniowa - ${ obiekt?.mieszkaniec?.imie_nazwisko } - ${obiekt?.nota} - ${ msc }.pdf`;

      // setActiveDock(obiekt.nota);
      setShowAll(true);

      setWysylkaStan( 
        prevState => ({
          ...prevState,   // Zachowanie poprzedniego stanu
          [`stan_${obiekt?.nota}`]: "Generuję pdf..."        // Dodanie nowego klucza 'wiek' z wartością 20
        })
      )
      const dataToSend = {
        html: htmlContent,
        fileName,
        email: obiekt.mieszkaniec.email,
        miesiac: msc
      }

      try {
        const response = await axios.post('/api/generate-pdf', dataToSend);

        if (response.data.success) {
          setWysylkaStan( 
            prevState => ({
              ...prevState,   // Zachowanie poprzedniego stanu
              [`stan_${obiekt?.nota}`]: "Plik pdf zapisany na serwerze."        // Dodanie nowego klucza 'wiek' z wartością 20
            })
          );
          
          ref.style.display = "none"
          // addToHistory(obiekt);
        } 
      } catch (error) {
        console.log("error: ", error)
        setWysylkaStan( 
          prevState => ({
            ...prevState,   // Zachowanie poprzedniego stanu
            [`stan_${obiekt?.nota}`]: "Wystąpił błąd."        // Dodanie nowego klucza 'wiek' z wartością 20
          })
        );
      }

    }
    setActiveDock(0);
    setShowAll(false);
  };

  const generateDocks = () => {
    return new Promise((resolve, reject) =>{
      const wygenerowanyKodHTML = tablicaObiektow?.map((obj, index) => {
        setWysylkaStan( 
          prevState => ({
            ...prevState,   // Zachowanie poprzedniego stanu
            [`stan_${obj?.nota}`]: "Przygotouję dane..."        // Dodanie nowego klucza 'wiek' z wartością 20
          })
        )
        return (
          <div           
            id={`header_${obj.nota}`}
            ref={(el) => 
              (divRefs.current[index] = {ref: el, refNr: obj.nota})
            }
            key={index}
            // style={ activeDock == obj.nota || showAll ? {display: "block"} : {display: "none"}}
            // style={{display: "none"}}
            className={`previewDock n_${obj.nota}`}
          >
            <NotaA4 
                
                componentRef={null}   
                wspolnota={wspolnota} 
                mieszkaniec={obj.mieszkaniec}
                tablicaCzynsze={tablicaCzynsze}
                udzialGrupaNota={obj.udzialyKontaAkt_u}
                lokale={lokale}
                datyNoty={obliczDatyNoty()}
                setWysylkaStan={setWysylkaStan}
                nota={obj.nota}
                notaIndex={index}
                setLoaderOn={setLoaderOn}
                liczbaNot={tablicaObiektow?.length}
            />
          </div>
        )
        
      });
      setDockList(wygenerowanyKodHTML);
      resolve();
    })
  }

    



  

  const handleHide = () => {
    setActiveDock(0);
    setShowAll(false);
  }

  const onClick = () => {
    setLoaderOn(true);
    setShowAll(true);
    generateDocks()
      .then(()=>{
        return delay(5000)
      })
      .then(()=>{
        console.log('b')
        handleGeneratePDF();
        setLoaderOn(false);
      })
      .catch( error => {
        console.log('Error: ', error)
      });
  }

  return (
    <div>
      {/* Dynamicznie generowane komponenty na podstawie tablicy obiektów */}
      <button
        onClick={onClick}
      >
        Wygeneruj pdf-y
      </button>
      <button
        // onClick={handleGenerateAndSendAllPdfs}
        disabled
        onClick={handleGeneratePDF}
      >
          Wyślij noty e-mailem
      </button>
      <div 
        id="docks"
        
      />
        {docksList}


      
    </div>
  );
};

export default NotyMasowa;